// extracted by mini-css-extract-plugin
export const root = "Form-module--root--1z4cy";
export const row = "Form-module--row--1yGFs";
export const success = "Form-module--success--2u2bB";
export const active = "Form-module--active--3Kfw5";
export const successInner = "Form-module--successInner--3yvxF";
export const header = "Form-module--header--1JcE7";
export const form = "Form-module--form--1O7Gy";
export const input = "Form-module--input--2nweD";
export const required = "Form-module--required--1UXBL";
export const phoneWrapper = "Form-module--phoneWrapper--1zoTn";
export const phoneInput = "Form-module--phoneInput--2kUoI";
export const subheader = "Form-module--subheader--252EF";
export const button = "Form-module--button--2uw5a";