import React from "react"
import cx from "classnames"

import * as s from "./Button.module.sass"

type ButtonProps = {
  type: "button" | "submit" | "reset" | undefined
  className?: string
} & React.HTMLProps<HTMLButtonElement>

export const Button: React.FC<ButtonProps> = ({
  type = "button",
  className,
  children,
  ...props
}) => {
  return (
    <button {...props} type={type} className={cx(s.root, className)}>
      {children}
    </button>
  )
}
