import React from "react"
import cx from "classnames"

import * as s from "./Input.module.sass"

type InputProps = {
  type?: string
  error?: string
  inputClassName?: string
  textarea?: boolean
} & React.InputHTMLAttributes<HTMLInputElement> &
  React.TextareaHTMLAttributes<HTMLTextAreaElement>

export const Input: React.FC<InputProps> = ({
  type = "text",
  error,
  inputClassName,
  className,
  value,
  required,
  textarea = false,
  ...props
}) => {
  const compoundClassName = cx(s.input, inputClassName, {
    [s.textarea]: textarea,
  })

  return (
    <div className={cx(s.root, className, { [s.error]: error })}>
      {textarea ? (
        <textarea
          {...props}
          value={value}
          className={compoundClassName}
          required={required}
        />
      ) : (
        <input
          {...props}
          type={type}
          value={value}
          className={compoundClassName}
          required={required}
        />
      )}
      {required && <span className={s.required}>*</span>}

      <div className={s.errorContainer}>
        {error && <div className={cx(s.errorText)}>{error}</div>}
      </div>
    </div>
  )
}
