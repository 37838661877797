import React, { useContext, useState } from "react"
import cx from "classnames"
import emailjs from "emailjs-com"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"

import { LayoutContext } from "@layouts/BaseLayout"
import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import { Input } from "@ui/Input"
import { Button } from "@ui/Button"

import * as s from "./Form.module.sass"

type FormProps = {
  className?: string
}

export const Form: React.FC<FormProps> = ({ className }) => {
  const { langKey } = useContext(LayoutContext)

  const [formState, setFormState] = useState({
    loading: false,
    sent: false,
  })

  const sendEmail = (e: any) => {
    e.preventDefault()
    e.target.checkValidity()
    setFormState({
      loading: true,
      sent: false,
    })

    emailjs
      .sendForm(
        process.env.GATSBY_EMAILJS_SERVICE_ID as string,
        process.env.GATSBY_EMAILJS_TEMPLATE_ID as string,
        e.target,
        process.env.GATSBY_EMAILJS_USER_ID as string
      )
      .then(
        result => {
          e.target.reset()
          setFormState({
            loading: false,
            sent: true,
          })
          setTimeout(() => {
            setFormState({
              loading: false,
              sent: false,
            })
          }, 3000)
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <section className={cx(s.root, className)}>
      <Container>
        <Row className={s.row}>
          <h1 className={s.header}>
            {langKey === "ua" ? "Форма для замовлення" : "Форма для заказа"}
          </h1>
          <form className={s.form} onSubmit={sendEmail}>
            <Input
              className={s.input}
              name="form_name"
              value="Главная страница"
              hidden
            />
            <Input
              className={s.input}
              name="first_name"
              placeholder={langKey === "ua" ? "Ваше ім’я:" : "Ваше имя:"}
              required
            />
            <Input
              className={s.input}
              name="second_name"
              placeholder={
                langKey === "ua" ? "Ваше прізвище:" : "Ваша фамилия:"
              }
            />
            <div className={s.input}>
              <span className={s.required}>*</span>
              <PhoneInput
                containerClass={s.phoneWrapper}
                inputClass={s.phoneInput}
                country={"ua"}
                inputProps={{
                  name: "phone_number",
                  required: true,
                }}
              />
            </div>
            <Input
              className={s.input}
              name="content"
              placeholder={
                langKey === "ua" ? "Ваше повідомлення:" : "Ваше уведомление:"
              }
              textarea
            />
            <h1 className={s.subheader}>
              {langKey === "ua" ? "Параметри замовлення:" : "Параметры заказы:"}
            </h1>
            <Input
              className={s.input}
              name="equipment_type"
              placeholder={
                langKey === "ua" ? "Тип обладнання:" : "Тип оборудования:"
              }
            />
            <Input
              className={s.input}
              name="building_size"
              placeholder={
                langKey === "ua" ? "Розмір приміщення:" : "Размер помещения:"
              }
            />
            <Input
              className={s.input}
              name="additional_parametrs"
              placeholder={
                langKey === "ua"
                  ? "Додаткові параметри"
                  : "Дополнительные параметры"
              }
              textarea
            />
            <div className={cx(s.success, { [s.active]: formState.sent })}>
              <div className={s.successInner}>
                {langKey === "ua"
                  ? "Дякуємо за Ваше звернення! Незабаром ми зв'яжемося з Вами."
                  : "Спасибо за Вашу заявку! В скором времени мы свяжемся с Вами."}
              </div>
            </div>
            <Button
              type="submit"
              className={s.button}
              disabled={formState.loading || formState.sent}
            >
              {formState.loading
                ? langKey === "ua"
                  ? "Отправка..."
                  : "Відправка..."
                : langKey === "ua"
                ? "Отправить"
                : "Відправити"}
            </Button>
          </form>
        </Row>
      </Container>
    </section>
  )
}
